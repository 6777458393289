import { createStore } from 'vuex';
import axios from 'axios';

export default createStore({
  state: {
    episodes: []
  },
  mutations: {
    SET_EPISODES(state, episodes) {
      state.episodes = episodes;
    }
  },
  actions: {
    async fetchEpisodes({ commit }) {
      try {
        const apiUrl = 'https://api.spreaker.com/v2/shows/4534835/episodes?limit=99';
        const response = await axios.get(apiUrl);
        const episodes = response.data.response.items.map(item => ({
          title: item.title,
          description: item.description,
          length: item.duration,
          audio: item.download_url
        }));

        commit('SET_EPISODES', episodes);
      } catch (error) {
        console.error('Error fetching podcast episodes:', error);
      }
    }
  }
});
