<template>
  <div class="home">
    <img
      class="logo-img logo-lg"
      alt="Same crit logo"
      src="../assets/album.jpg"
    />
    <div class="subscribe">
      <div class="subscribe-contain">
        <a
          target="_blank"
          href="https://www.twitch.tv/samecritdifferentday"
          class="site-banner twitch-button"
          >Subscribe on Twitch</a
        >
      </div>
      <div class="subscribe-contain">
        <a
          target="_blank"
          href="https://podcasts.apple.com/podcast/id1526948092?app=podcast&at=1000lPBj&ls=1&mt=2"
          class="subscribe-button"
          style="background-position: -230px 7px"
          >Listen on Apple Podcasts</a
        >
        <a
          target="_blank"
          href="https://open.spotify.com/show/3sMTVnBHLqAOoM0aC0C0OE"
          class="subscribe-button"
          style="background-position: -230px -53px"
          >Listen on Spotify</a
        >
        <a
          target="_blank"
          href="https://podcasts.google.com/feed/aHR0cHM6Ly93d3cuc3ByZWFrZXIuY29tL3Nob3cvNDUzNDgzNS9lcGlzb2Rlcy9mZWVk"
          class="subscribe-button"
          style="background-position: -230px -113px"
          >Listen on Google Podcasts</a
        >
        <a
          target="_blank"
          href="https://overcast.fm/itunes1526948092"
          class="subscribe-button"
          style="background-position: -230px -233px"
          >Listen on Overcast</a
        >
        <a
          target="_blank"
          href="https://a.co/d/4mbENqP"
          class="subscribe-button"
          style="background-position: -230px -293px"
          >Listen on Amazon Music</a
        >
        <a
          target="_blank"
          href="https://castbox.fm/vic/1526948092"
          class="subscribe-button"
          style="background-position: -230px -353px"
          >Listen on Castbox</a
        >
        <a
          target="_blank"
          href="https://podcastaddict.com/podcast/3065013"
          class="subscribe-button"
          style="background-position: -230px -413px"
          >Listen on Podcast Addict</a
        >
        <a
          target="_blank"
          href="https://pca.st/itunes/1526948092"
          class="subscribe-button"
          style="background-position: -230px -473px"
          >Listen on Pocket Casts</a
        >
        <a
          target="_blank"
          href="https://www.iheart.com/podcast/70911493"
          class="subscribe-button"
          style="background-position: -230px -533px"
          >Listen on iHeartRadio</a
        >
        <a
          target="_blank"
          href="https://www.youtube.com/@SameCritDifferentDay"
          class="subscribe-button"
          style="background-position: -230px -717px"
          >Listen on YouTube</a
        >
        <a
          target="_blank"
          href="https://www.spreaker.com/show/4534835/episodes/feed"
          class="subscribe-button"
          style="background-position: -230px -653px"
          >RSS Feed</a
        >
      </div>
      <div class="subscribe-contain">
        <a
          target="_blank"
          href="https://discord.gg/pNvAGmT9Hm"
          class="site-button discord-button"
          >Join Our Discord</a
        >
        <a
          target="_blank"
          href="https://www.instagram.com/samecritdifferentday/"
          class="site-button instagram-button"
          >Follow Us on Instagram</a
        >
        <a
          target="_blank"
          href="mailto:samecritpodcast@gmail.com"
          class="site-button email-button"
          >Contact Us</a
        >
      </div>
    </div>
    <div class="podcast-desc-contain">
      <p class="podcast-description p-font p-size">
        Same Crit Different Day is an actual play Pathfinder 2nd Edition podcast
        where a group of friends sit around the table and enjoy some good ole
        classic tabletop roleplaying, laughs, songs and more!<br /><br />Pathfinder
        is a product of <a href="https://paizo.com/">Paizo</a> as well as the
        Adventure Path The Extinction Curse.<br /><br />All background
        music/ambience is from
        <a href="https://tabletopaudio.com/">Tabletop Audio</a>
        <br /><br />Special thanks to
        <a href="https://soundcloud.com/willowispproductions"
          >Will O'Wisp Productions</a
        >
        for the amazing and wonderful chiptunes and 8 bit music.
        <br /><br />Almost all chiptunes are made custom by Will O'Wisp however
        there are a few from other independent creators on Fiverr.com<br /><br />And
        one more final special thanks to Paizo for making an amazing product and
        bringing friends and strangers together!<br /><br />So join us at the
        table for some Pathfinder 2E and lets roll some dice!.. and do some
        rippin and the tearin! <br /><br />Please send us your feedback! You can
        email the show at
        <a href="mailto:samecritpodcast@gmail.com">samecritpodcast@gmail.com</a>
      </p>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "HomeView",
  components: {},
};
</script>
<style lang="scss" scoped>
.podcast-desc-contain {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.subscribe {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.subscribe-contain {
  width: 100vw;
  @media (min-width: 769px) {
    max-width: 60%;
  }
}
.podcast-description {
  width: 100vw;
  @media (min-width: 769px) {
    max-width: 60%;
  }
}
.subscribe-button {
  display: inline-block;
  background-color: #ffffff;
  background-image: url(https://www.buzzsprout.com/images/badges/listen-on-embed.svg);
  background-repeat: no-repeat;
  border: 1px solid #000000;
  border-radius: 5px;
  margin: 0 5px 5px 0;
  text-indent: -9000px;

  height: 40px;
  width: 162px;
}
.site-button {
  display: inline-block;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-size: cover;
  border: 1px solid #000000;
  border-radius: 5px;
  margin: 0 5px 5px 0;
  height: 36px;
  width: 162px;
  text-indent: -9000px; /* Hide text off-screen */
}
.site-banner {
  display: inline-block;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 15px 15px 0;
  height: 90px;
  width: 320px;
  text-indent: -9000px; /* Hide text off-screen */
}
@media screen and (min-width: 768px) {
  .site-banner {
    height: 180px; /* Adjust height for desktop */
    width: 640px; /* Adjust width for desktop */
  }
}
.discord-button {
  background-image: url(../assets/discord-logo.jpg);
}
.instagram-button {
  background-image: url(../assets/instagram-logo.png);
}
.email-button {
  background-image: url(../assets/email-logo.jpg);
}
.twitch-button {
  background-image: url(../assets/twitch-banner.png);
}
a {
  text-decoration: none; /* No underline */
  font-variant: small-caps; /* Small caps */
  font-weight: 900;
  color: rgb(82, 169, 255); /* Inherit color from parent */
}
</style>
