<template>
  <nav>
    <div class="nav-container">
      <router-link class="p-font" to="/">Home</router-link> |
      <router-link class="p-font" to="/episodes">Episodes</router-link> |
      <router-link class="p-font" to="/characters">Characters</router-link>
    </div>
  </nav>
  <router-view />
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  display: flex;
  justify-content: center;
  .nav-container {
    padding: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    min-width: 360px;
    a {
      font-weight: bold;
      color: #6d9bc8;

      &.router-link-exact-active {
        color: #42b983;
      }
    }
  }
}
body {
  background-color: #17171f;
}
hr {
  border-block-color: #6d9bc8;
  width: 80vw;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  color: #dde;
}
.logo-img {
  max-width: 20vw;

  &.logo-lg {
    min-width: 300px;
    border-radius: 20%;
  }

  &.logo-sm {
    min-width: 30px;
    border-radius: 15%;
  }
}
.dark-text {
  color: #fff;
}
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap");
.p-font {
  font-family: "Lato", sans-serif;
  &.i {
    font-style: italic;
  }
}
.p-size {
  font-size: 1.2rem;
}
@import url("https://fonts.googleapis.com/css2?family=Ruwudu:wght@600&display=swap");
.h-font {
  font-family: "Ruwudu", serif;
}
.h-lg {
  font-size: 2.5em;
}
</style>
